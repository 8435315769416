import * as React from "react";
import { Segment} from "semantic-ui-react";
import "../../css/intro.css";
import "animate.css/animate.compat.css";
import Hero from './hero';
import WhyBetter from './whybetter';
import AllYouNeed from './allyouneed';
import UncompromisingSuplement from './uncompromisingsuplement';
import WhichWay from "./whichway";
import NewIntroBlog from "./newintroblog";
import QuestionsForm from "./questionsform";
import NewFooter from '../pages/newfooter';
import SEO from "../seo";

export default class Intro extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment" id="new-intro">
        <SEO/>
        <Hero/>
        <WhyBetter/>
        <AllYouNeed/>
        <UncompromisingSuplement/>
        <div id="ways">
          <WhichWay/>
        </div>
        <NewIntroBlog data={this.props.data}/>
        <QuestionsForm/>
        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}