import * as React from "react";
import {
  Button, Container, Grid, Header, Segment
} from "semantic-ui-react";
import {t} from "../../i18n";
import { Link } from 'gatsby';
import IntroBackgroundHero from "../backgrounds/newintro/Hero";

export default class Hero extends React.Component {
  render() {
    return (
      <IntroBackgroundHero>
        <Segment basic id="intro-1-banner" className="intro-fullwidth-component">
          <Container>
            <Segment basic id='hero-container'>
              <Grid stackable>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  <Header as="h1" id='hero-title'>Suplement w <b>100% dopasowany</b> do Ciebie</Header>
                  <p id="hero-paragraph">Zawiera tylko składniki, których potrzebujesz. W odpowiednich
                    porcjach, w jednej saszetce dziennie.</p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-hero"
                      as={Link} to='/#ways'>
                      {t("stwórz swój skład")}
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Container>
        </Segment>
      </IntroBackgroundHero>
    );
  }
}