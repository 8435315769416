import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import {
  Container, Grid, Header, Segment, Image, Button
} from "semantic-ui-react";
import IntroBackgroundWhichWay from '../backgrounds/newintro/WhichWay';
import { Link } from 'gatsby';

export default class WhichWay extends React.Component {
  render() {
    return (
      <IntroBackgroundWhichWay>
        <Segment basic textAlign="center" style={{paddingTop: '100px', margin: 0}}>
          <Container>
          <Header as='h2' id='intro-h2'>Zdecyduj <b>w jaki sposób</b> chcesz spersonalizować swój suplement</Header>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Segment basic padded className="whichway-container">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/way_a.png' alt="Wybór pierwszy" placeholder="none"/>
                  </Segment>
                <div className="whichway-container">
                  <div>
                    Po wypełnieniu ankiety przygotowanej przez nasz Zespół Naukowy otrzymasz propozycję <b>dopasowanego do Twoich potrzeb sumplementu.</b>
                  </div>
                  <div style={{paddingTop: 14}}>
                    <Button primary as={Link} to='/survey'>wypełnij ankietę!</Button>
                  </div>
                </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
              <Segment basic padded className="whichway-container">
                <Segment basic>
                  <StaticImage src='../../img/newintro/way_b.png' alt="Wybór drugi" placeholder="none"/>
                </Segment>
                <div className="whichway-container">
                  <div>
                    <b>Stwórz samodzielnie</b> skład swojego suplementu, a my wyprodukujemy go dla Ciebie.
                  </div>
                  <div style={{paddingTop: 14}}>
                    <Button secondary as={Link} to='/checkorder' onClick={() => localStorage.setItem('order', 'normal')}>stwórz swój skład!</Button>
                  </div>
                </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Container>
        </Segment>
      </IntroBackgroundWhichWay>
    );
  }
}