import * as React from "react";
import {
  Container,
  Grid, Header, Segment
} from "semantic-ui-react";
import { StaticImage } from "gatsby-plugin-image";
import IntroListItem from "../utils/IntroListItem";

export default class UncompromisingSuplement extends React.Component {
  render() {
    return (
      <Segment basic style={{margin: 0}}>
        <Container>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Segment basic>
                <Header as='h2' id='intro-h2'><b>Bezkompromisowy</b><br /> 
                  suplement
                </Header>
                <p>Każdy <b>skład tworzymy indywidualnie</b> z listy 40-stu starannie wyselekcjonowanych 
                  składników o <b>naukowo udowodnionym działaniu.</b></p>
                <div>
                  <IntroListItem padded><b>Bez wypełniaczy</b>, konserwantów i zbędnych substancji</IntroListItem>
                  <IntroListItem padded><b>Bez cukru</b> i sztucznych barwników</IntroListItem>
                  <IntroListItem padded><b>Bezpieczny skład</b> w optymalnych porcjach</IntroListItem>
                  <IntroListItem padded><b>Jedna saszetka</b> zastępuje nawet kilkanaście zwykłych suplementów</IntroListItem>
                  <IntroListItem padded><b>Nie musisz się martwić</b> o akumulację i duplikację składników</IntroListItem>
                  <IntroListItem padded><b>Ekologiczne</b> opakowanie bez plastiku</IntroListItem>
                </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <StaticImage src="../../img/newintro/uncompromising.png" alt="Suplementy i posiłek" placeholder="none"/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}