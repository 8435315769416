import * as React from "react";
import {
  Container, Grid, Header, Segment, Button
} from "semantic-ui-react";
import IntroBackgroundWhyBetter from '../backgrounds/newintro/WhyBetter';
import { StaticImage } from "gatsby-plugin-image";
import { Link } from '../../helpers/router';
import IntroListItem from "../utils/IntroListItem";

export default class WhyBetter extends React.Component {
  render() {
    return (
      <IntroBackgroundWhyBetter>
        <Segment basic id="section-whybetter-container">
          <Segment basic>
            <Container>
              <Grid columns={2} stackable>
                <Grid.Row>
                  <Grid.Column verticalAlign='middle'>
                    <StaticImage src="../../img/newintro/why_better.png" alt="Suplementy" placeholder="none"/>
                  </Grid.Column>
                  <Grid.Column style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Header as='h2' id='intro-h2'>Dlaczego<br /> 
                      <b>pure4me jest lepszy</b>
                    </Header>
                    <div>
                      <IntroListItem padded>
                        <b>Jedyny personalizowany</b> suplement stworzony przez firmę farmaceutyczną
                      </IntroListItem>
                      <IntroListItem padded>
                        <b>Skład dobieramy indywidualnie</b> pod Twoje potrzeby, w zależności od wieku, płci, stylu życia i potrzeb zdrowotnych
                      </IntroListItem>
                      <IntroListItem padded>
                        Receptury stworzone przez ekspertów w oparciu o <b>badania naukowe</b>
                      </IntroListItem>
                      <IntroListItem padded>
                        <b>Jedna saszetka dziennie</b> może zastąpić nawet kilkanaście zwykłych suplementów
                      </IntroListItem>
                      <IntroListItem padded>
                        <b>Gwarancja jakości</b> – własne laboratoria analityne oraz farmaceutyczny starndard produkcji
                      </IntroListItem>
                    </div>
                    <div style={{padding: '14px 0'}}>
                      <Button className="newintro-primary-button" primary as={Link} to='/quality'>Dowiedz się więcej o jakości</Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Segment>
      </IntroBackgroundWhyBetter>
    );
  }
}