import * as React from "react";
import { Button, Checkbox, Form, Input, TextArea } from "semantic-ui-react";
import "../../css/intro.css";
import { t } from "../../i18n";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";

interface StateProps {
	contactMessage: any
}

export function mapStateToProps(state: any) {
	return {
		contactMessage: state.sendContactMessage,
	};
}

interface DispatchProps {
	sendContactMessage: typeof actions.sendContactMessage,

}

const mapDispatchToProps = {
	sendContactMessage: actions.sendContactMessage,
};

type Props = StateProps & DispatchProps;

interface State {
	name: string,
	email: string,
	subject: string,
	content: string,
	statements: Array<string>,
	isButtonDisabled: boolean,
	messageStatus: number
}

class NewContactForm extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			subject: "",
			content: "",
			statements: [],
			isButtonDisabled: false,
			messageStatus: null,
		}
	}
	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.contactMessage !== this.props.contactMessage) {
			this.setState({
				messageStatus: this.props.contactMessage.status
			})
		}
		if (this.state.messageStatus === 200) {
			this.setState({
				name: "",
				email: "",
				subject: "",
				content: "",
				statements: [],
				isButtonDisabled: false,
				messageStatus: null,

			})
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	}

	handleValueChange(e, { name, value }) {
		this.setState({ [name]: value })
	}

	setButtonDisabled() {
		this.setState({
			isButtonDisabled: true
		})
	}

	setContactData(e) {
		if (!this.checkStatements()) {
			toast.error("Wymagane pole jest niezaznaczone", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return
		}
		this.setButtonDisabled()
		e.preventDefault();
		let formData = {
			name: this.state.name,
			email: this.state.email,
			subject: this.state.subject,
			content: this.state.content,
			statements: this.state.statements,
		}
		this.props.sendContactMessage(formData)
	}

	checkStatements() {
		return this.state.statements.includes("pure_contact")
	}

	setStatements(e, { name }) {
		const a = this.state.statements;
		if (a.includes(name)) {
			a.splice(a.indexOf(name), 1)
		} else {
			a.push(name)
		}
		this.setState({
			statements: a
		})
	}

	render() {
		return (
			<Form onSubmit={this.setContactData.bind(this)} className="new-contact-form">
				<Form.Field
					name={"name"}
					value={this.state.name}
					control={Input}
					required
					className="new-contact-form-input"
					onChange={this.handleValueChange.bind(this)}
					placeholder='Imię i nazwisko'
				/>
				<Form.Field
					name={"email"}
					value={this.state.email}
					control={Input}
					required
					className="new-contact-form-input"
					onChange={this.handleValueChange.bind(this)}
					placeholder='Twój e-mail'
				/>
				<Form.Field
					name={"subject"}
					value={this.state.subject}
					control={Input}
					required
					className="new-contact-form-input"
					onChange={this.handleValueChange.bind(this)}
					placeholder='Temat'
				/>
				<Form.Field
					name={"content"}
					value={this.state.content}
					control={TextArea}
					required
					className="new-contact-form-input"
					onChange={this.handleValueChange.bind(this)}
					placeholder='Twoja wiadomość'
				/>
				<Form.Field>
					<Button disabled={this.state.isButtonDisabled} secondary type='submit' style={{margin: '28px 0', width: 300}}>wyślij</Button>
				</Form.Field>
				<Form.Field required>
					<Checkbox
						id="statement-one"
						label={t('pure_contact')}
						name="pure_contact"
						value='one'
						checked={this.state.statements.includes("pure_contact")}
						onClick={this.setStatements.bind(this)}
					/>
				</Form.Field>
				<Form.Field>
					<Checkbox
						id="statement-two"
						label={t('pure_marketing')}
						name='pure_marketing'
						value='two'
						checked={this.state.statements.includes("pure_marketing")}
						onClick={this.setStatements.bind(this)}
					/>
				</Form.Field>
				<Form.Field>
					<Checkbox
						id="statement-three"
						label={t('pure_newsletter')}
						name='pure_newsletter'
						value='three'
						checked={this.state.statements.includes("pure_newsletter")}
						onClick={this.setStatements.bind(this)}
					/>
				</Form.Field>
				<p style={{ color: "black", fontSize: "0.8em" }}>* pole wymagane</p>
			</Form>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewContactForm);


