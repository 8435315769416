import * as React from "react";
import {
  Container, Segment
} from "semantic-ui-react";
import NewBlog from '../pages/newBlog';
import IntroBackgroundNewIntroBlog from '../backgrounds/newintro/NewIntroBlog';

export default class NewIntroBlog extends React.Component {
  render() {
    return (
      <IntroBackgroundNewIntroBlog>
        <Segment basic textAlign="center" style={{margin: 0}}>
          <Container>
            <NewBlog data={this.props.data} nopadding/>
          </Container>
        </Segment>
      </IntroBackgroundNewIntroBlog>
    );
  }
}