import * as React from "react";
import {
  Button, Container, Grid, Header, Image, Segment
} from "semantic-ui-react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from '../../helpers/router';
import IntroBackgroundAllYouNeed from "../backgrounds/newintro/AllYouNeed";

export default class AllYouNeed extends React.Component {
  render() {
    return (
      <IntroBackgroundAllYouNeed>
        <Segment basic style={{ marginTop: 0, marginBottom: 0}}>
          <Container>
            <Segment basic textAlign="center">
              <Header as='h2' id='intro-h2'>Wszystko czego <b>potrzebujesz</b></Header>
              <p>Powiedz nam jakie są <b>Twoje cele zdrowotne</b>, a my pomożemy Ci je osiągnąć <br />
                przygotowując jedną saszetkę <b>suplementu tak wyjątkowego jak Ty!</b></p>
            </Segment>
          
            <Grid columns={3} stackable style={{paddingTop: 30, paddingBottom: 50}}>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/1_energy.png' alt="Energia" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Energia</b><br />
                      do działania
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/2_immunity.png' alt="Odporność" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Odporność</b><br />
                      na najwyższym poziomie
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/3_memory.png' alt="Pamięć" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Sprawny umysł,</b><br />
                      pamięć i koncentracja
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/4_skin.png' alt="Włosy" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Zdrowe włosy,</b><br />
                      skóra i paznokcie
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/5_lacium.png' alt="Zdrowe jelita" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Zdrowe jelita</b><br />
                      i wątroba
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Segment basic>
                    <StaticImage src='../../img/newintro/6_joints.png' alt="Pamięć" placeholder="none"/>
                    <div style={{paddingTop: 14}}>
                      <b>Stawy</b><br />
                      w dobrej kondycji
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>


            <div id='know-more-container'>
              <div id='know-more'><Header as="h2" style={{display: "block"}}>...i dowiedz się co jeszcze!</Header></div>
              <div><Button secondary className='btn-secondary shadow-light' as={Link} to='/#ways'>stwórz
                swój skład!</Button></div>
            </div>

          </Container>
        </Segment>
      </IntroBackgroundAllYouNeed>
    );
  }
}