import * as React from "react";
import {
  Container, Grid, Header, Segment
} from "semantic-ui-react";
import { StaticImage } from "gatsby-plugin-image";
import NewContactForm from "../pages/newContactForm";

export default class QuestionsForm extends React.Component {
  render() {
    return (
      <Segment basic id='section-form'>
        <Container>
          <Grid centered stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Segment basic>
                  <StaticImage src='../../img/newintro/contact.png' alt='Formularz kontaktowy' placeholder='none'/>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <div style={{paddingBottom: 28}}>
                  <Header as='h2' style={{marginBottom: 0}}>Masz pytania?</Header>
                  <Header as='h2' id="intro-h2" style={{marginTop: 8}}><b>Napisz</b> do nas!</Header>       
                </div>
                <NewContactForm/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}