import React from 'react';
import {Container, Divider, Grid, Header, Image, Segment} from "semantic-ui-react";
import BlogCard from '../utils/blogcard';
import _ from "lodash";

const NewBlog = ({data, nopadding}) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Segment basic style={{paddingTop: nopadding ? 0 : "150px", marginTop: 0}}>
      <Container>
        <Grid centered stackable>
          {!nopadding && <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Header as='h1' className='section-subheader' id='blog-header'>Blog</Header>
            </Grid.Column>
          </Grid.Row>}
          <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Header as='h2' className="section-header">Nasze ostatnie wpisy</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Divider hidden/>
            </Grid.Column>
          </Grid.Row>
          
          <Grid.Row>
          {posts && _.map(posts, ({excerpt, frontmatter}, i: number) => (
            <Grid.Column width={4} key={i}>
              <BlogCard 
                image={frontmatter.featuredImage?.childImageSharp.gatsbyImageData}
                date={frontmatter.articleDate}
                title={frontmatter.title}
                uri={frontmatter.path}
              >
                {excerpt}
              </BlogCard>
            </Grid.Column>
          ))}
          </Grid.Row>
          
        </Grid>
      </Container>
    </Segment>
  );
}

export default NewBlog;
