import * as React from "react"
import FullApp from "../FullApp";
import Layout from "../templates/layout";
import Intro from '../components/newintro/intro';
import { graphql } from 'gatsby';

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Intro data={props.data}/>
    </Layout>
  </FullApp>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 4) {
      nodes {
        excerpt
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          articleDate
          path
          name
        }
      }
    }
  }
`