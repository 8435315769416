
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Segment, Item } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';

const arrow_r = '/img/utils/arrow-right.png';

const BlogCard = ({image, date, title, children, uri}) => {
  const gatsbyImage = getImage(image)
  return (
    <Segment basic id="card">
      <div style={{marginBottom: '1em'}}>
        <GatsbyImage image={gatsbyImage} alt={title} id="image-container"/>
      </div>
      <div>
        <p className="card-date">{date}</p>
        <p className="card-title">{title}</p>
        <p className="card-content">{children}</p>
      </div>
      <Item as="a" href={uri} style={{display: "block"}}>
        <div className="card-read-more-container"><p className="card-read-more">czytaj więcej</p>
        <Image id="card-arrow" width={43} height={11} src={arrow_r} alt="Dalej"/></div>
      </Item>
    </Segment>
  )
}

export default BlogCard;