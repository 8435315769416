import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImg } from '../BackgroundImg';

const IntroBackgroundHero = ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "backgrounds/newintro/intro_hero_bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        mobile: file(relativePath: { eq: "backgrounds/newintro/intro_hero_bg_mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  let imageData = data.desktop;
  let width = 1000;
  if(typeof window !== `undefined`){
    width = window.innerWidth;
  }
  if(width < 768){
    imageData = data.mobile;
  }
  return (
    <BackgroundImg image={imageData} className="intro-1-banner" id='intro-hero-bg'>
      {children}
    </BackgroundImg>
  )
}

export default IntroBackgroundHero;
